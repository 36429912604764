export const PARTIAL_SEARCH_PARAM = "fresh-partial";
export const PARTIAL_ATTR = "f-partial";
export const LOADING_ATTR = "f-loading";
export const CLIENT_NAV_ATTR = "f-client-nav";
export const DATA_KEY_ATTR = "data-fresh-key";
export const DATA_CURRENT = "data-current";
export const DATA_ANCESTOR = "data-ancestor";

export const enum PartialMode {
  REPLACE,
  APPEND,
  PREPEND,
}

// denoCacheMetadata={"headers":{"server":"deno/gcp-us-east4","referrer-policy":"strict-origin-when-cross-origin","x-frame-options":"DENY","content-type":"application/typescript; charset=utf-8","age":"3192987","last-modified":"Tue, 07 Nov 2023 18:33:27 GMT","via":"http/2 edgeproxy-h","date":"Sat, 16 Nov 2024 17:20:58 GMT","cross-origin-opener-policy":"same-origin","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","cross-origin-embedder-policy":"same-origin","vary":"Accept-Encoding, Origin","etag":"\"cbf7306b660163352728761242054293\"","x-amz-cf-id":"CLzf0MZ1n25pWX6yZ70rmvFB014eF27XhAXx2O5DhmP-DbbqngqZzA==","x-amz-server-side-encryption":"AES256","x-cache":"Hit from cloudfront","x-content-type-options":"nosniff","cross-origin-resource-policy":"same-origin","server-timing":"fetchSource;dur=10","x-amz-cf-pop":"IAD61-P1","content-length":"386","x-amz-replication-status":"COMPLETED","access-control-allow-origin":"*","x-amz-version-id":"6iySwPPYBwBqVmlddOIE3RtbNEZz8WQH","accept-ranges":"bytes","strict-transport-security":"max-age=63072000; includeSubDomains; preload","cache-control":"public, max-age=31536000, immutable"},"url":"https://deno.land/x/fresh@1.5.4/src/constants.ts","time":1734970644}