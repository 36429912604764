import { Sheet } from "twind";
import { Options, setup, STYLE_ELEMENT_ID } from "./shared.ts";

type State = [string, string][];

export default function hydrate(options: Options, state: State) {
  const el = document.getElementById(STYLE_ELEMENT_ID) as HTMLStyleElement;
  const rules = new Set<string>();
  const precedences: number[] = [];
  const mappings = new Map(
    state.map((v) => typeof v === "string" ? [v, v] : v),
  );
  // deno-lint-ignore no-explicit-any
  const sheetState: any[] = [precedences, rules, mappings, true];
  const target = el.sheet!;
  const ruleText = Array.from(target.cssRules).map((r) => r.cssText);
  for (const r of ruleText) {
    const m = r.lastIndexOf("/*");
    const precedence = parseInt(r.slice(m + 2, -2), 36);
    const rule = r.slice(0, m);
    rules.add(rule);
    precedences.push(precedence);
  }
  const sheet: Sheet = {
    target,
    insert: (rule, index) => target.insertRule(rule, index),
    init: (cb) => cb(sheetState.shift()),
  };
  setup(options, sheet);
}

// denoCacheMetadata={"headers":{"strict-transport-security":"max-age=63072000; includeSubDomains; preload","cross-origin-opener-policy":"same-origin","age":"3164011","server-timing":"fetchSource;dur=6","x-cache":"Hit from cloudfront","x-frame-options":"DENY","x-content-type-options":"nosniff","cache-control":"public, max-age=31536000, immutable","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","vary":"Accept-Encoding, Origin","cross-origin-resource-policy":"same-origin","cross-origin-embedder-policy":"same-origin","referrer-policy":"strict-origin-when-cross-origin","via":"http/2 edgeproxy-h","content-length":"1021","accept-ranges":"bytes","etag":"\"e835cdf2adf3d06eb24db09bac09265c\"","content-type":"application/typescript; charset=utf-8","x-amz-cf-id":"AGt2Xm8duOZ3MiEU-vnGRaQkwzayjXAP1M31xwpiA1w6EUwNEKldDg==","x-amz-cf-pop":"IAD61-P1","x-amz-server-side-encryption":"AES256","x-amz-version-id":"ZQfmTFMkxigUN3pM.WFapDSFexOyZMfA","last-modified":"Tue, 07 Nov 2023 18:33:27 GMT","server":"deno/gcp-us-east4","date":"Sun, 17 Nov 2024 01:24:00 GMT","access-control-allow-origin":"*","x-amz-replication-status":"COMPLETED"},"url":"https://deno.land/x/fresh@1.5.4/plugins/twind/main.ts","time":1734970650}